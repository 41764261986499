import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const LockIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.5" y="3" width="35" height="42" rx="2" style={{ fill: SAGE_100 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.5 5H8.5L8.5 43H39.5V5ZM8.5 3C7.39543 3 6.5 3.89543 6.5 5V43C6.5 44.1046 7.39543 45 8.5 45H39.5C40.6046 45 41.5 44.1046 41.5 43V5C41.5 3.89543 40.6046 3 39.5 3H8.5ZM14.2432 13.45C14.2432 12.8977 14.6909 12.45 15.2432 12.45L32.7432 12.45C33.2954 12.45 33.7432 12.8977 33.7432 13.45C33.7432 14.0023 33.2954 14.45 32.7432 14.45L15.2432 14.45C14.6909 14.45 14.2432 14.0023 14.2432 13.45ZM15.2432 19.45C14.6909 19.45 14.2432 19.8977 14.2432 20.45C14.2432 21.0023 14.6909 21.45 15.2432 21.45L32.7432 21.45C33.2954 21.45 33.7432 21.0023 33.7432 20.45C33.7432 19.8977 33.2954 19.45 32.7432 19.45L15.2432 19.45ZM14.2432 27.45C14.2432 26.8977 14.6909 26.45 15.2432 26.45L32.7432 26.45C33.2954 26.45 33.7432 26.8977 33.7432 27.45C33.7432 28.0023 33.2954 28.45 32.7432 28.45L15.2432 28.45C14.6909 28.45 14.2432 28.0023 14.2432 27.45ZM15.5 33.45C14.9477 33.45 14.5 33.8977 14.5 34.45C14.5 35.0023 14.9477 35.45 15.5 35.45H32.5C33.0523 35.45 33.5 35.0023 33.5 34.45C33.5 33.8977 33.0523 33.45 32.5 33.45H15.5Z"
      style={{ fill: ALOE_100 }}
    />
    <circle cx="34.75" cy="13" r="13" style={{ fill: MINT_100 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.75 24C40.8251 24 45.75 19.0751 45.75 13C45.75 6.92487 40.8251 2 34.75 2C28.6749 2 23.75 6.92487 23.75 13C23.75 19.0751 28.6749 24 34.75 24ZM34.75 26C41.9297 26 47.75 20.1797 47.75 13C47.75 5.8203 41.9297 0 34.75 0C27.5703 0 21.75 5.8203 21.75 13C21.75 20.1797 27.5703 26 34.75 26Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.75 5C36.8211 5 38.5 6.67893 38.5 8.75V10.0945C39.7939 10.4275 40.75 11.6021 40.75 13V17C40.75 18.6569 39.4069 20 37.75 20H31.75C30.0931 20 28.75 18.6569 28.75 17V13C28.75 11.6021 29.7061 10.4275 31 10.0945L31 8.75C31 6.67893 32.6789 5 34.75 5ZM36.5 8.75V10H33L33 8.75C33 7.7835 33.7835 7 34.75 7C35.7165 7 36.5 7.7835 36.5 8.75ZM31.75 12C31.1977 12 30.75 12.4477 30.75 13V17C30.75 17.5523 31.1977 18 31.75 18H37.75C38.3023 18 38.75 17.5523 38.75 17V13C38.75 12.4477 38.3023 12 37.75 12L37.5 12L31.75 12ZM35.75 14.5C35.75 13.9477 35.3023 13.5 34.75 13.5C34.1977 13.5 33.75 13.9477 33.75 14.5V15.5C33.75 16.0523 34.1977 16.5 34.75 16.5C35.3023 16.5 35.75 16.0523 35.75 15.5V14.5Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
