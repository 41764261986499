import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const CreditIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="9" width="38" height="27" rx="2" style={{ fill: SAGE_100 }} />
    <rect x="7" y="11" width="34" height="5" style={{ fill: MINT_100 }} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11H41V16L7 16L7 11ZM5 17V11C5 9.89543 5.89543 9 7 9H41C42.1046 9 43 9.89543 43 11V17V34C43 35.1046 42.1046 36 41 36H7C5.89543 36 5 35.1046 5 34V17ZM41 18V34H7L7 18L41 18ZM24.25 23C24.25 22.4477 24.6977 22 25.25 22L35 22C35.5523 22 36 22.4477 36 23C36 23.5523 35.5523 24 35 24L25.25 24C24.6977 24 24.25 23.5523 24.25 23ZM28 28C27.4477 28 27 28.4477 27 29C27 29.5523 27.4477 30 28 30H32C32.5523 30 33 29.5523 33 29C33 28.4477 32.5523 28 32 28H28Z"
      style={{ fill: ALOE_100 }}
    />
    <circle cx="19" cy="29.5" r="10" style={{ fill: MINT_100 }} />
    <mask
      id="mask0_2577_22800"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="21"
      width="16"
      height="17"
    >
      <circle cx="19" cy="29.5" r="8" style={{ fill: MINT_100 }} />
    </mask>
    <g mask="url(#mask0_2577_22800)">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 25L11 25L14.5 21L23 21L27 25Z"
        style={{ fill: ALOE_100 }}
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 31L11 31L11 27L27 27L27 31Z"
        style={{ fill: ALOE_100 }}
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 37L14 37L11 33L27 33L24 37Z"
        style={{ fill: ALOE_100 }}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 29.5C27 33.9183 23.4183 37.5 19 37.5C14.5817 37.5 11 33.9183 11 29.5C11 25.0817 14.5817 21.5 19 21.5C23.4183 21.5 27 25.0817 27 29.5ZM25.3287 37.2429C23.6049 38.6536 21.4013 39.5 19 39.5C13.4772 39.5 9 35.0228 9 29.5C9 23.9772 13.4772 19.5 19 19.5C24.5228 19.5 29 23.9772 29 29.5C29 31.9013 28.1536 34.1049 26.7429 35.8287L34.2071 43.2929C34.5976 43.6834 34.5976 44.3166 34.2071 44.7071C33.8166 45.0976 33.1834 45.0976 32.7929 44.7071L25.3287 37.2429Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
