import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const ShieldIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      style={{ fill: SAGE_100 }}
      d="M8.00064 15.4432C7.99947 14.5823 8.55309 13.8193 9.36235 13.5257C15.5482 11.2818 20.6153 8.22297 22.8166 6.7951C23.5336 6.33005 24.4664 6.33005 25.1834 6.7951C27.3847 8.22297 32.4518 11.2818 38.6376 13.5257C39.4469 13.8193 40.002 14.5819 39.9911 15.4427C39.886 23.7485 38.8377 30.2164 36 34C33 38 26.4439 42 24 42C21.6808 42 16 39 12 34C8.2177 29.2721 8.01185 23.6501 8.00064 15.4432Z"
    />
    <mask
      style={{ maskType: 'alpha' }}
      id="mask0_3147_23712"
      maskUnits="userSpaceOnUse"
      x="7"
      y="5"
      width="34"
      height="38"
    >
      <path
        style={{ fill: SAGE_100 }}
        stroke={ALOE_100}
        strokeWidth="2"
        d="M8.00064 15.4432C7.99947 14.5823 8.55309 13.8193 9.36235 13.5257C15.5482 11.2818 20.6153 8.22297 22.8166 6.7951C23.5336 6.33005 24.4664 6.33005 25.1834 6.7951C27.3847 8.22297 32.4518 11.2818 38.6376 13.5257C39.4469 13.8193 40.002 14.5819 39.9911 15.4427C39.886 23.7485 38.8377 30.2164 36 34C33 38 26.4439 42 24 42C21.6808 42 16 39 12 34C8.2177 29.2721 8.01185 23.6501 8.00064 15.4432Z"
      />
    </mask>
    <g mask="url(#mask0_3147_23712)">
      <rect x="24" y="6" width="16" height="36" style={{ fill: MINT_100 }} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      style={{ fill: ALOE_100 }}
      d="M24.6393 7.63404C24.2534 7.38371 23.7468 7.38371 23.3609 7.63404C21.1265 9.08339 15.9883 12.1859 9.70345 14.4658C9.27525 14.6211 9.00015 15.0179 9.00073 15.4418C9.01202 23.7108 9.25131 28.9632 12.781 33.3753C14.7017 35.7762 17.0328 37.7033 19.1586 39.0259C20.2207 39.6866 21.2181 40.1881 22.0726 40.5215C22.9464 40.8624 23.6015 41 24.0001 41C24.3968 41 25.1081 40.8208 26.0852 40.3956C27.03 39.9843 28.1241 39.3874 29.2485 38.6603C31.5104 37.1976 33.7955 35.2728 35.2001 33.4C36.4956 31.6727 37.4258 29.2702 38.04 26.2197C38.653 23.1759 38.9391 19.5506 38.9912 15.43C38.9965 15.017 38.7275 14.622 38.2967 14.4658C32.0119 12.1859 26.8737 9.08339 24.6393 7.63404ZM22.2725 5.95611C23.3205 5.27635 24.6797 5.27635 25.7277 5.95611C27.8959 7.36251 32.8918 10.3776 38.9788 12.5857C40.1665 13.0165 41.0076 14.1467 40.9911 15.4553C40.9381 19.6405 40.6476 23.402 40.0007 26.6145C39.3551 29.8203 38.3423 32.5436 36.8001 34.6C35.2047 36.7272 32.7118 38.8024 30.3346 40.3397C29.1395 41.1125 27.9488 41.7657 26.8833 42.2294C25.85 42.6791 24.8253 43 24.0001 43C23.2391 43 22.3144 42.7626 21.3457 42.3847C20.3578 41.9993 19.2502 41.4383 18.1021 40.7241C15.8078 39.2967 13.2985 37.2238 11.2192 34.6247C7.18427 29.581 7.01185 23.5895 7.00074 15.4445C6.99896 14.1466 7.83111 13.0175 9.02143 12.5857C15.1083 10.3776 20.1043 7.36251 22.2725 5.95611Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      style={{ fill: ALOE_100 }}
      d="M23.1638 17.6154C23.4175 16.7924 24.5824 16.7924 24.8361 17.6154L26.0813 21.6546H30.1423C30.9787 21.6546 31.3383 22.7158 30.6743 23.2243L27.3499 25.7701L28.6132 29.8681C28.8642 30.6824 27.9216 31.3386 27.245 30.8205L24 28.3355L20.7549 30.8205C20.0783 31.3386 19.1357 30.6824 19.3867 29.8681L20.65 25.7701L17.3257 23.2243C16.6616 22.7158 17.0212 21.6546 17.8577 21.6546H21.9186L23.1638 17.6154Z"
    />
  </svg>
);
