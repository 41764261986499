import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const CompareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
    <ellipse cx="32.5" cy="12.6667" rx="3.33333" ry="3.33333" style={{ fill: SAGE_100 }} />
    <path
      d="M23.1666 36.6667C23.1666 42.1895 18.6895 46.6667 13.1666 46.6667C7.64378 46.6667 3.16663 42.1895 3.16663 36.6667H23.1666Z"
      style={{ fill: MINT_100 }}
    />
    <path
      d="M61.8333 29.3333C61.8333 34.8562 57.3561 39.3333 51.8333 39.3333C46.3104 39.3333 41.8333 34.8562 41.8333 29.3333H61.8333Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5956 12.8374C37.5589 15.1796 35.9558 17.1415 33.7877 17.7217L33.7877 54.7187H42.3515C43.0878 54.7187 43.6848 55.3157 43.6848 56.0521C43.6848 56.7884 43.0878 57.3854 42.3515 57.3854H22.5586C21.8222 57.3854 21.2252 56.7884 21.2252 56.0521C21.2252 55.3157 21.8222 54.7187 22.5586 54.7187H31.1211V17.7211C29.7675 17.3583 28.6343 16.4567 27.9653 15.2604C27.8875 15.2892 27.8059 15.3112 27.721 15.3254L14.7244 17.5055L23.5216 35.0998C23.792 35.6407 23.9648 36.2835 23.9215 36.9723C23.5529 42.8427 18.6766 47.4889 12.7141 47.4889C6.75152 47.4889 1.87519 42.8427 1.50662 36.9723C1.46337 36.2835 1.63616 35.6407 1.90658 35.0998L11.5215 15.87C11.7474 15.4183 12.209 15.1329 12.7141 15.1329C12.725 15.1329 12.736 15.1331 12.7469 15.1333L27.2799 12.6955C27.2916 12.6935 27.3034 12.6917 27.3151 12.69C27.35 9.88089 29.6381 7.61441 32.4555 7.61441C34.3903 7.61441 36.0754 8.68326 36.9527 10.2627C37.0259 10.2365 37.1025 10.2165 37.1819 10.2031L51.8225 7.74721C51.8718 7.73895 51.9209 7.7335 51.9696 7.73075C52.0451 7.71747 52.1224 7.71061 52.2007 7.71061C52.7057 7.71061 53.1674 7.99595 53.3933 8.44766L63.0082 27.6775C63.2786 28.2183 63.4514 28.8612 63.4082 29.55C63.0396 35.4204 58.1633 40.0666 52.2007 40.0666C46.2382 40.0666 41.3618 35.4204 40.9933 29.55C40.95 28.8612 41.1228 28.2183 41.3932 27.6775L49.8401 10.7837L37.6231 12.8331L37.5956 12.8374ZM34.9296 12.7552C34.9296 14.1216 33.8219 15.2293 32.4555 15.2293C31.0891 15.2293 29.9814 14.1216 29.9814 12.7552C29.9814 11.3888 31.0891 10.2811 32.4555 10.2811C33.8219 10.2811 34.9296 11.3888 34.9296 12.7552ZM44.4617 27.5035L52.2007 12.0254L59.9398 27.5035H44.4617ZM43.7406 30.1701C44.3806 34.2657 47.9251 37.3999 52.2007 37.3999C56.4764 37.3999 60.0209 34.2657 60.6608 30.1701H43.7406ZM12.7141 19.4477L4.97502 34.9258H20.4531L12.7141 19.4477ZM12.7141 44.8223C8.43842 44.8223 4.89391 41.688 4.254 37.5924H21.1741C20.5342 41.688 16.9897 44.8223 12.7141 44.8223Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
