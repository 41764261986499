import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const PhoneSVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="3" width="24" height="42" rx="4" style={{ fill: SAGE_100 }} />
    <path
      d="M12 34H36V41C36 43.2091 34.2091 45 32 45H16C13.7909 45 12 43.2091 12 41V34Z"
      style={{ fill: MINT_100 }}
    />
    <circle cx="24" cy="39.5" r="1.5" style={{ fill: ALOE_100 }} />
    <path
      d="M12 7C12 4.79086 13.7909 3 16 3H32C34.2091 3 36 4.79086 36 7V11H12V7Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 7C34 5.89543 33.1046 5 32 5H16C14.8954 5 14 5.89543 14 7V9H34V7ZM34 11H14V34H34V11ZM12 11V9V7C12 4.79086 13.7909 3 16 3H32C34.2091 3 36 4.79086 36 7V9V11V34V36V41C36 43.2091 34.2091 45 32 45H16C13.7909 45 12 43.2091 12 41V36V34V11ZM14 41V36H34V41C34 42.1046 33.1046 43 32 43H16C14.8954 43 14 42.1046 14 41Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
