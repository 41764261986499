import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const AcceptedOfferSVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.5"
      y="4"
      width="33"
      height="40"
      rx="1"
      style={{ stroke: ALOE_100, fill: SAGE_100 }}
      strokeWidth="2"
    />
    <path
      d="M15.2432 13.45L32.7432 13.45"
      style={{ stroke: ALOE_100 }}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.2432 20.45L32.7432 20.45"
      style={{ stroke: ALOE_100 }}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.2432 27.45L32.7432 27.45"
      style={{ stroke: ALOE_100 }}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M15.5 34.45H32.5" style={{ stroke: ALOE_100 }} strokeWidth="2" strokeLinecap="round" />
    <circle
      cx="34.5"
      cy="29.2"
      r="9"
      style={{ stroke: ALOE_100, fill: MINT_100 }}
      strokeWidth="2"
    />
    <path
      d="M30.25 29.1979L33.1579 32.1058L38.75 26.29"
      style={{ stroke: ALOE_100, fill: MINT_100 }}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
