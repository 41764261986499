import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const TalkLargeIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C2.79086 10 1 11.7909 1 14V27C1 29.2091 2.79086 31 5 31H8V36.25L16.75 31H25C27.2091 31 29 29.2091 29 27V14C29 11.7909 27.2091 10 25 10H5Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11C3.34315 11 2 12.3431 2 14V27C2 28.6569 3.34315 30 5 30H8C8.55228 30 9 30.4477 9 31V34.4838L16.2355 30.1425C16.3909 30.0493 16.5688 30 16.75 30H25C26.6569 30 28 28.6569 28 27V14C28 12.3431 26.6569 11 25 11H5ZM0 14C0 11.2386 2.23858 9 5 9H25C27.7614 9 30 11.2386 30 14V27C30 29.7614 27.7614 32 25 32H17.027L8.5145 37.1075C8.20556 37.2929 7.82081 37.2977 7.5073 37.1202C7.19379 36.9427 7 36.6103 7 36.25V32H5C2.23858 32 0 29.7614 0 27V14Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43 17C45.2091 17 47 18.7909 47 21V34C47 36.2091 45.2091 38 43 38H40V43.25L31.25 38H23C20.7909 38 19 36.2091 19 34V21C19 18.7909 20.7909 17 23 17H43Z"
      style={{ fill: SAGE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43 18C44.6569 18 46 19.3431 46 21V34C46 35.6569 44.6569 37 43 37H40C39.4477 37 39 37.4477 39 38V41.4838L31.7645 37.1425C31.6091 37.0493 31.4312 37 31.25 37H23C21.3431 37 20 35.6569 20 34V21C20 19.3431 21.3431 18 23 18H43ZM48 21C48 18.2386 45.7614 16 43 16H23C20.2386 16 18 18.2386 18 21V34C18 36.7614 20.2386 39 23 39H30.973L39.4855 44.1075C39.7944 44.2929 40.1792 44.2977 40.4927 44.1202C40.8062 43.9427 41 43.6103 41 43.25V39H43C45.7614 39 48 36.7614 48 34V21ZM26 24.5C26 23.9477 26.4477 23.5 27 23.5H39C39.5523 23.5 40 23.9477 40 24.5C40 25.0523 39.5523 25.5 39 25.5H27C26.4477 25.5 26 25.0523 26 24.5ZM27 29.5C26.4477 29.5 26 29.9477 26 30.5C26 31.0523 26.4477 31.5 27 31.5H39C39.5523 31.5 40 31.0523 40 30.5C40 29.9477 39.5523 29.5 39 29.5H27Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
