import { ALOE_100, CACTUS_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const ChartSVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="24" r="20" style={{ fill: SAGE_100 }} />
    <path
      d="M45 24C45 21.3736 44.4827 18.7728 43.4776 16.3463C42.4725 13.9198 40.9993 11.715 39.1421 9.85786C37.285 8.00069 35.0802 6.5275 32.6537 5.52241C30.2272 4.51732 27.6264 4 25 4L25 24L45 24Z"
      style={{ fill: MINT_100 }}
    />
    <path
      d="M45 24C45 26.6264 44.4827 29.2272 43.4776 31.6537C42.4725 34.0802 40.9993 36.285 39.1421 38.1421L25 24L45 24Z"
      style={{ fill: CACTUS_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.7094 38.1236L24.2929 24.7071C24.2703 24.6845 24.2487 24.6608 24.2284 24.6361C24.0947 24.4742 24.0111 24.2695 24.001 24.0457M24 23.9921V5.02586C13.9714 5.54588 6 13.842 6 24C6 34.4934 14.5066 43 25 43C29.8869 43 34.3429 41.155 37.7094 38.1236M26 23H43.9737C43.8602 20.8466 43.3808 18.7257 42.5537 16.729C41.5989 14.4238 40.1993 12.3293 38.435 10.565C36.6707 8.80066 34.5762 7.40113 32.271 6.44629C30.2743 5.61922 28.1534 5.13982 26 5.02633V23ZM27.4142 25L39.1235 36.7093C40.5659 35.1064 41.7266 33.2677 42.5537 31.271C43.3808 29.2743 43.8602 27.1534 43.9737 25H27.4142ZM4 24C4 12.402 13.402 3 25 3C30.7789 3 36.0127 5.33428 39.8098 9.1114L39.8492 9.15076L39.8886 9.19021C43.6657 12.9873 46 18.2211 46 24C46 29.7757 43.6683 35.0069 39.8949 38.8035C39.8797 38.8188 39.8645 38.834 39.8492 38.8492C39.849 38.8495 39.8487 38.8498 39.8484 38.8501C36.0482 42.6498 30.7986 45 25 45C13.402 45 4 35.598 4 24Z"
      style={{ fill: ALOE_100 }}
    />
    <rect
      x="1"
      y="23"
      width="18"
      height="15"
      rx="1"
      fill="white"
      stroke={ALOE_100}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 24H2V37H18V24ZM2 22C0.895431 22 0 22.8954 0 24V37C0 38.1046 0.895431 39 2 39H18C19.1046 39 20 38.1046 20 37V24C20 22.8954 19.1046 22 18 22H2ZM5 28C5 27.4477 5.44772 27 6 27H14C14.5523 27 15 27.4477 15 28C15 28.5523 14.5523 29 14 29H6C5.44772 29 5 28.5523 5 28ZM6 32C5.44772 32 5 32.4477 5 33C5 33.5523 5.44772 34 6 34H14C14.5523 34 15 33.5523 15 33C15 32.4477 14.5523 32 14 32H6Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
