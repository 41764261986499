import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const TransparentSVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46 18C46 26.8366 38.8366 34 30 34C21.1634 34 14 26.8366 14 18C14 9.16344 21.1634 2 30 2C38.8366 2 46 9.16344 46 18Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 32C37.732 32 44 25.732 44 18C44 10.268 37.732 4 30 4C22.268 4 16 10.268 16 18C16 25.732 22.268 32 30 32ZM30 34C38.8366 34 46 26.8366 46 18C46 9.16344 38.8366 2 30 2C21.1634 2 14 9.16344 14 18C14 26.8366 21.1634 34 30 34Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      d="M34 30C34 38.8366 26.8366 46 18 46C9.16344 46 2 38.8366 2 30C2 21.1634 9.16344 14 18 14C26.8366 14 34 21.1634 34 30Z"
      style={{ fill: SAGE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 44C25.732 44 32 37.732 32 30C32 22.268 25.732 16 18 16C10.268 16 4 22.268 4 30C4 37.732 10.268 44 18 44ZM18 46C26.8366 46 34 38.8366 34 30C34 21.1634 26.8366 14 18 14C9.16344 14 2 21.1634 2 30C2 38.8366 9.16344 46 18 46Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 32C37.732 32 44 25.732 44 18C44 10.268 37.732 4 30 4C22.268 4 16 10.268 16 18C16 25.732 22.268 32 30 32ZM30 34C38.8366 34 46 26.8366 46 18C46 9.16344 38.8366 2 30 2C21.1634 2 14 9.16344 14 18C14 26.8366 21.1634 34 30 34Z"
      style={{ fill: ALOE_100 }}
    />
  </svg>
);
